import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['suggestionForm', 'suggestionFormButton']

  static values = { suggestionFormShow: { type: Boolean, default: false } }

  connect() {
    if (this.suggestionFormShowValue) {
      this.showSuggestionForm()
    }
  }

  toggleSuggestionForm() {
    if (this.isSuggestionFormOpen) {
      this.hideSuggestionForm()
    } else {
      this.showSuggestionForm()
    }
  }

  closeSuggestionForm() {
    this.suggestionFormButtonTarget.querySelector('ds-icon').classList.remove('rotate-180')
    this.suggestionFormTarget.classList.add('scale-y-0')
    this.suggestionFormTarget.classList.remove('scale-y-100')
  }

  showSuggestionForm() {
    this.suggestionFormButtonTarget.querySelector('ds-icon').classList.add('rotate-180')
    this.suggestionFormTarget.querySelector('textarea')?.focus()
    this.suggestionFormTarget.querySelector('textarea')?.setSelectionRange(-1, -1)
    this.suggestionFormTarget.classList.add('scale-y-100')
    this.suggestionFormTarget.classList.remove('scale-y-0')
  }

  get isSuggestionFormOpen() {
    return this.suggestionFormTarget.classList.contains('scale-y-100')
  }

  hideSuggestionForm() {
    this.suggestionFormButtonTarget.querySelector('ds-icon').classList.remove('rotate-180')
    this.suggestionFormTarget.classList.add('scale-y-0')
    this.suggestionFormTarget.classList.remove('scale-y-100')
  }
}
