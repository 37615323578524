import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash/debounce'

export default class extends Controller {
  elementTop = null

  connect() {
    this.elementTop = parseInt(window.getComputedStyle(this.element).top, 10)

    this.resizeObserver = new ResizeObserver(debounce(() => {
      this.elementTop = parseInt(window.getComputedStyle(this.element).top, 10)
    }, 300))

    this.resizeObserver.observe(this.element)
    window.addEventListener('scroll', this.onScroll)
    this.onScroll()
  }

  disconnect() {
    this.resizeObserver?.disconnect()
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    if (!this.elementTop) return
    const currentTop = Math.round(this.element.getBoundingClientRect().top)
    this.element.dataset.sticking = currentTop <= this.elementTop
  }
}
