import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'input', 'button']

  connect() {
    this.buttonTarget.disabled = false

    // disable form submiting on enter for each input
    this.inputTargets.forEach(input => {
      input.addEventListener('keydown', e => {
        if (e.key === 'Enter') {
          e.preventDefault()
          this.formSubmit()
        }
      })
    })

    // enable form submiting on enter for form only
    this.formTarget.addEventListener('keydown', e => {
      const hasModifier = e.metaKey || e.ctrlKey || e.shiftKey || e.altKey

      if (e.key === 'Enter' && !hasModifier) {
        if (!e.defaultPrevented) {
          this.formSubmit()
        }
      }
    })

    this.formTarget.addEventListener('turbo:submit-start', () => {
      this.isSubmitting = true
      this.buttonTarget.disabled = true
    })

    this.formTarget.addEventListener('turbo:submit-end', () => {
      setTimeout(() => {
        this.resetSubmissionState()
      }, 250)
    })

    this.formTarget.addEventListener('turbo:submit-error', () => {
      this.resetSubmissionState()
    })

    this.formTarget.addEventListener('turbo:error', () => {
      this.resetSubmissionState()
    })
  }

  resetSubmissionState() {
    this.isSubmitting = false
    this.buttonTarget.disabled = false
  }

  formSubmit() {
    if (this.buttonTarget.disabled || this.isSubmitting || this.formTarget.submitting) {
      return false
    }

    this.isSubmitting = true
    this.buttonTarget.disabled = true
    this.formTarget.requestSubmit()
  }
}
