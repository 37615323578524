/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'warningHint',
    'checkbox',
    'priceInput',
    'confirmationModal',
    'saveButtonNotice'
  ]

  previouslyChecked = false
  isUpdatingPrice = false
  isConfirmed = false

  connect() {
    this.previouslyChecked = this.checkboxTarget.checked
  }

  handleDurationChange(event) {
    if (event.target.value[0] === '365') {
      this.disableCheckbox()
    } else {
      this.enableCheckbox()
    }
  }

  disableCheckbox() {
    this.previouslyChecked = this.checkboxTarget.checked
    this.warningHintTarget.classList.remove('hidden')
    this.checkboxTarget.setAttribute('disabled', 'disabled')
    this.checkboxTarget.checked = false
  }

  enableCheckbox() {
    this.warningHintTarget.classList.add('hidden')
    this.checkboxTarget.removeAttribute('disabled')
    this.checkboxTarget.checked = this.previouslyChecked
  }

  handlePriceChange() {
    this.isUpdatingPrice = this.priceInputTargets.some(i => {
      return +i.dataset.changingPriceCount > 0
    })

    this.saveButtonNoticeTargets.forEach(target => {
      target.classList.toggle('hidden', !this.isUpdatingPrice)
    })
  }

  confirm() {
    this.isConfirmed = true
    this.element.requestSubmit()
  }

  handleFormSubmit(event) {
    if (this.isUpdatingPrice && !this.isConfirmed) {
      event.preventDefault()
      this.confirmationModalTarget.open()
    }
  }
}
