import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    'modal', 'uploadVideoPlaceholder', 'attachedVideoPreview',
    'attachedVideoIdField', 'uploadedVideoIdField',
    'attachedVideoPreviewTitle', 'attachedVideoPreviewDesc',
    'attachedVideoPreviewDuration', 'attachedVideoPreviewImg',
    'searchInput', 'loader', 'videoImageUploader', 'ugcTooltip', 'searchUrl'
  ]

  openModal(e) {
    if (e.currentTarget.hasAttribute('aria-disabled')) return
    this.search()
    this.modalTarget.open()
  }

  selectVideo(event) {
    const {
      id, title, desc, duration, img
    } = event.currentTarget.dataset

    this.setAttachedVideo(id)
    this.displayPreview(title, desc, duration, img)
    this.modalTarget.close()
  }

  deattachVideo() {
    this.attachedVideoPreviewTarget.classList.add('hidden')
    this.attachedVideoIdFieldTarget.value = null
    if (this.hasUploadedVideoIdFieldTarget) {
      this.uploadedVideoIdFieldTarget.value = null
    }
  }

  displayPreview(title, desc, duration, imgSrc) {
    this.attachedVideoPreviewImgTarget.src = imgSrc
    this.attachedVideoPreviewTitleTarget.textContent = title
    this.attachedVideoPreviewDescTarget.textContent = desc
    this.attachedVideoPreviewDurationTarget.textContent = duration
    this.attachedVideoPreviewTarget.classList.remove('hidden')
  }

  setAttachedVideo(id) {
    this.attachedVideoIdFieldTarget.value = id
    if (this.hasUploadedVideoIdFieldTarget) {
      this.uploadedVideoIdFieldTarget.value = null
    }
  }

  setUploadedVideo(id) {
    this.attachedVideoIdFieldTarget.value = null
    this.uploadedVideoIdFieldTarget.value = id
    this.loaderTarget.hideLoader()
    this.uploadVideoPlaceholderTarget.classList.replace('block', 'hidden')
  }

  search() {
    const { searchUrl } = this.searchInputTarget.dataset

    new FetchRequest('get', searchUrl, {
      query: {
        search: this.searchInputTarget.value,
        frame_id: this.searchInputTarget.dataset.frameId
      }
    }).perform()
  }
}
