import { Controller } from '@hotwired/stimulus'
import confetti from 'canvas-confetti'

export default class extends Controller {

  static values = {
    delayTime: Number,
  }

  connect() {
    if (this.canvas === undefined) this.createCanvas()

    // Create confetti object
    const { canvas } = this
    this.confetti = confetti.create(canvas, { resize: true })

    setTimeout(() => {
      this.fireAll()
    }, this.delayTimeValue)

    // Remove canvas after 5 seconds
    setTimeout(() => {
      document.body.removeChild(canvas)
    }, this.delayTimeValue + 5000)
  }

  fireAll() {
    // Fire!
    this.fire(0.6, { spread: 26, startVelocity: 55, scalar: 1.5 })
    this.fire(0.8, { spread: 60, scalar: 1.3 })
    this.fire(0.7, { spread: 100, decay: 0.91, scalar: 1.3 })
    this.fire(0.4, { startVelocity: 25, decay: 0.92, scalar: 1.5 })
    this.fire(0.4, { startVelocity: 45, scalar: 1.4 })
    this.fire(0.6, { startVelocity: 50, angle: 45, spread: 50, scalar: 1.5 })
    this.fire(0.6, { startVelocity: 55, angle: 140, spread: 50, scalar: 1.6 })
  }

  fire(particleRatio, options) {
    const count = 200
    const defaults = { origin: { y: 0.7 }, disableForReducedMotion: true }

    this.confetti({ ...defaults, ...options, particleCount: Math.floor(count * particleRatio) })
  }

  createCanvas() {
    document.body.insertAdjacentHTML(
      'beforeend',
      '<canvas id="confetti" class="fixed inset-0 w-full h-full pointer-events-none z-50"></canvas>'
    )
  }

  get canvas() {
    return document.querySelector('#confetti')
  }
}
